import * as Styled from './search-input-dropdown.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface SearchInputDropdownProps {
    targetDirs: string[]
    setSearchValue: (value: string) => void
    setChange: (value: string) => void
    setIsExpanded: (value: boolean) => void
    isEmpty: boolean
    isblockedDir: boolean
}

export function SearchInputDropdown({
    targetDirs,
    setSearchValue,
    setChange,
    setIsExpanded,
    isEmpty,
    isblockedDir,
}: SearchInputDropdownProps): JSX.Element {
    const {width} = useDimensions()

    function onClickHandler(e: string) {
        setSearchValue(e)
        setChange(e)
        setIsExpanded(false)
    }

    return (
        <>
            {(!isEmpty && (
                <Styled.Dropdown width={width}>
                    {targetDirs.map((targetDir) => (
                        <Styled.Ul key={targetDir} onClick={() => onClickHandler(targetDir)}>
                            <Styled.Li width={width}>{targetDir}</Styled.Li>
                        </Styled.Ul>
                    ))}
                </Styled.Dropdown>
            )) || (
                <Styled.Dropdown width={width}>
                    <Styled.Ul>
                        <Styled.Li width={width} style={{textAlign: 'center'}}>
                            - No Matched Target Found -
                        </Styled.Li>
                    </Styled.Ul>
                </Styled.Dropdown>
            )}
            {isblockedDir && (
                <Styled.Dropdown width={width}>
                    <Styled.Ul>
                        <Styled.Li width={width} style={{textAlign: 'center', color: '#ff002b'}}>
                            /tmp is not acceptable as target directory
                        </Styled.Li>
                    </Styled.Ul>
                </Styled.Dropdown>
            )}
        </>
    )
}
