import {useQuartermaster} from '../../../../pages/dashboard/contexts/use-quartermaster'
import * as Styled from './show-result-form.styled'
import {SubmitProgress} from './submit-progress/submit-progress'

export function ShowResult(): JSX.Element {
    const {filename, submitResult} = useQuartermaster()
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()}>
            <Styled.Header>{`${filename}.artefact`}</Styled.Header>
            <Styled.Actions>
                <SubmitProgress filename={filename} submitResult={submitResult} />
            </Styled.Actions>
        </Styled.Section>
    )
}
