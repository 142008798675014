import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useTheme} from 'styled-components'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fetchQmMessages} from '../../../../store/state/qm-messages/action-creators'
import {
    isLoadingSelector,
    lastUpdatedSelector,
    loadingStateSelector,
} from '../../../../store/state/qm-messages/selectors'
import {ContentContainerBottom} from '../../../../templates/fixed-page/content-container-bottom.styled'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import LoadingState from '../../../../values/loading-state-enum'
import {Footer} from '../footer/footer.styled'
import {Pagination} from '../footer/paging/pagination'
import {RecordSet} from '../footer/record-set/record-set'
import {Header} from '../shared/header/header'
import {TableHeader} from './components/table/header/qm-messages-list-table-header'
import {QmMessagesListLoaded} from './qm-messages-list-loaded'
import {QmMessagesTable} from './qm-messages-list.styled'

export function QmMessagesList(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const dispatch = useDispatch()

    const isLoading = useTypedSelector(isLoadingSelector)
    const loadingState = useTypedSelector(loadingStateSelector)
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    function refresh() {
        dispatch(fetchQmMessages())
    }
    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchQmMessages())
        }
    }, [dispatch, loadingState])

    return (
        <>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.chrList.dataArea.background}
            >
                <Header
                    text={'Quartermaster Messages'}
                    refreshFunction={refresh}
                    lastUpdate={lastUpdate}
                />
            </ContentContainerMiddle>
            <QmMessagesTable>
                <ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.chrList.dataArea.background}
                >
                    <TableHeader />
                </ContentContainerMiddle>
                <ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.chrList.dataArea.background}
                    scrollable={true}
                >
                    {isLoading ? <DataLoading /> : <QmMessagesListLoaded />}
                </ContentContainerMiddle>
                <ContentContainerBottom width={width}>
                    <Footer width={width}>
                        <Pagination />
                        <RecordSet />
                    </Footer>
                </ContentContainerBottom>
            </QmMessagesTable>
        </>
    )
}
