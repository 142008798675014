import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useAuthenticatedSemaphore} from '../hooks/use-authenticated-semaphore'
import useTypedSelector from '../hooks/use-typed-selector'
import {loggingOut} from '../store/state/session-data/action-creators'
import {logInStatusSelector} from '../store/state/session-data/selectors'
import {LoginStatus} from '../store/state/session-data/state'
import {AuthenticatedRoutes} from './authenticated-routes'
import {UnauthenticatedRoutes} from './unauthenticated-routes'

function App(): JSX.Element {
    const dispatch = useDispatch()
    const loginStatus = useTypedSelector(logInStatusSelector)
    const {authenticationSemaphoreStatus} = useAuthenticatedSemaphore()

    useEffect(() => {
        if (authenticationSemaphoreStatus) {
            // do nothing for now
        } else {
            if (
                loginStatus === null ||
                loginStatus === undefined ||
                loginStatus === LoginStatus.LOGGED_IN
            ) {
                dispatch(loggingOut())
            }
        }
    }, [authenticationSemaphoreStatus, dispatch, loginStatus])

    return loginStatus === LoginStatus.LOGGED_IN ? (
        <AuthenticatedRoutes />
    ) : (
        <UnauthenticatedRoutes />
    )
}

export default App
