import {createSelector} from 'reselect'
import {fetchingStatusSelector} from '../../../store/state/config/selectors'
import {ConfigFetchingStatus} from '../../../store/state/config/state'
import {environmentsLoadingSelector} from '../../../store/state/environments/selectors'

export const allDataIsLoadedReselector = createSelector(
    fetchingStatusSelector,
    environmentsLoadingSelector,
    (configFetchingStatus, fetchingCallHomeRequests): boolean => {
        return configFetchingStatus !== ConfigFetchingStatus.INITIALISED || fetchingCallHomeRequests
            ? false
            : true
    },
)
