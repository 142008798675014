import {LoadingModal} from '../../../../components/modals/submit-loading/submit-result-modal'
import {useQuartermaster} from '../../contexts/use-quartermaster'
import {ArtefactName} from './artefact-name/artefact-name'
import {CustomerVesselSelect} from './customer-vessel-select/customer-vessel-select'
import {DestinationSelect} from './destination-select/destination-select'
import {SubmitArea} from './submit-area/submit-area'
import {VesselSelectBox, VesselSelectWrapper} from './vessel-select.styled'

export function VesselSelect() {
    const {isSubmitProcessing} = useQuartermaster()
    return (
        <VesselSelectBox>
            <ArtefactName />
            <VesselSelectWrapper>
                <CustomerVesselSelect />
                <DestinationSelect />
            </VesselSelectWrapper>
            <SubmitArea />
            {isSubmitProcessing && <LoadingModal />}
        </VesselSelectBox>
    )
}
