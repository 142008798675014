import React from 'react'
import ReactDOM from 'react-dom'
import {AxiosInstance} from 'axios'
import {Store} from 'redux'
import {Provider} from 'react-redux'
import {createGlobalStyle} from 'styled-components'

import App from './root/app'
import reportWebVitals from './root/reportWebVitals'
import {makeAxios} from './api/axios-api/axios-api'
import AppState from './store/types/app-state'
import configureStore from './store/configure-store'
import {BrowserRouter as Router} from 'react-router-dom'
import {DimensionsProvider} from './contexts/dimensions/dimensions-provider'

import {ThemeProvider} from 'styled-components'
import {LightTheme} from './theme/light-theme'
import {DarkTheme} from './theme/dark-theme'

import {enableMapSet} from 'immer'

export let REST: AxiosInstance

const consoleStyles =
    'font-family: "Open Sans", sans-serif; margin: 10px; padding: 10px; font-size: 16px; background-color: #303030; font-weight: bold; color: #e7e7e7'

enableMapSet()

const store: Store<AppState> = configureStore()

makeAxios(store).then((httpClient) => {
    REST = httpClient

    // eslint-disable-next-line no-console
    console.info('%Quartermaster Web UI by CyberOwl', consoleStyles)

    // eslint-disable-next-line no-console
    console.info(
        `%cVersion: ${process.env.REACT_APP_QUARTERMASTER_WEB_VERSION}${
            process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
        }`,
        consoleStyles,
    )

    const darkMode = false

    const GlobalStyle = createGlobalStyle`
     @font-face {
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        src: url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
        font-weight: 300;
        font-style: normal;
    }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;

  }
  #root{
      scroll-behavior: smooth;
      min-height: 100vh;
      background-color: #e7e7e7;
      overflow: auto;
  }
`
    ReactDOM.render(
        <React.StrictMode>
            <GlobalStyle />
            <Provider store={store}>
                <Router>
                    <DimensionsProvider>
                        <ThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
                            <App />
                        </ThemeProvider>
                    </DimensionsProvider>
                </Router>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root') as HTMLElement,
    )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
