import {MouseEvent} from 'react'
import * as Styled from './cancel-button.styled'
import {useQuartermaster} from '../../../../../pages/dashboard/contexts/use-quartermaster'

interface CancelButtonProps {
    disabled: boolean
}

export function CancelButton({disabled}: CancelButtonProps): JSX.Element {
    const {setSubmitProcessing} = useQuartermaster()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setSubmitProcessing(false)
    }
    return (
        <Styled.CancelButton onClick={cancelChanges} disabled={disabled}>
            Cancel
        </Styled.CancelButton>
    )
}
