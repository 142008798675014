import useTypedSelector from '../../../../hooks/use-typed-selector'
import {qmMessagesSelector} from '../../../../store/state/qm-messages/selectors'
import {NoDataCard} from '../shared/no-data-card/no-data-card'

import {QmMessagesListTablePopulated} from './components/table/qm-messages-list-table-populated'

export function QmMessagesListLoaded(): JSX.Element {
    const qmMessages = useTypedSelector(qmMessagesSelector)
    return qmMessages?.length === 0 ? (
        <NoDataCard text="no data" />
    ) : (
        <QmMessagesListTablePopulated qmMessages={qmMessages} />
    )
}
