import JSZip from 'jszip'
import {REST} from '../../../..'
import {
    SubmitResult,
    WaybillData,
    WaybillDataAllCystomer,
} from '../../contexts/types/quartermaster-state'

export function createZipFile(
    filename: string,
    uploadedFile: File | undefined,
    commands: string,
    waybillData: WaybillData | WaybillDataAllCystomer,
    setSubmitResult: (value: SubmitResult) => void,
): JSZip {
    const zip = new JSZip()
    const formData = new FormData()
    zip.file(`${filename}.waybill`, JSON.stringify(waybillData))
    zip.file(`${filename}.installcmds`, commands)
    uploadedFile && zip.file(filename, uploadedFile)
    zip.generateAsync({type: 'blob'}).then((resZip) => {
        formData.append('file', resZip, `${filename}.artefact`)
        REST.post('/api/v1/artefact', formData, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN': 'csrfToken',
            },
        })
            .then((res) => {
                setSubmitResult({
                    status: 'success',
                    message: `${filename}.artefact ${res.data}.`.toLowerCase(),
                })
            })
            .catch((err) => {
                setSubmitResult({
                    status: 'failed',
                    message: `${err}`,
                })
            })
    })
    return zip
}
