import {ButtonStyled} from '../../../../../components/button/button.styled'
import * as Styled from './submit-area.styled'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {createZipFile} from '../../shared/upload-helper'

export function SubmitArea(): JSX.Element {
    const {
        setShowVesselSelect,
        waybillData,
        filename,
        commands,
        uploadedFile,
        setSubmitResult,
        setSubmitProcessing,
    } = useQuartermaster()

    return (
        <Styled.SubmitArea>
            <Styled.ButtonArea>
                <ButtonStyled
                    // disabled={false}
                    onClick={() => {
                        setShowVesselSelect(false)
                    }}
                >
                    Back
                </ButtonStyled>
                <ButtonStyled
                    disabled={false}
                    onClick={() => {
                        createZipFile(
                            filename,
                            uploadedFile,
                            commands,
                            waybillData,
                            setSubmitResult,
                        )
                        setSubmitProcessing(true)
                    }}
                >
                    Submit
                </ButtonStyled>
            </Styled.ButtonArea>
        </Styled.SubmitArea>
    )
}
