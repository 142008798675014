import {ChangeEvent, MutableRefObject, useRef, useState} from 'react'
import {X} from 'react-feather'
import {formatFileSize} from '../../shared/data-helper'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {TextArea} from '../data-text-area/data-text-area.styled'
import * as Styled from './install-cmd-upload.styled'

interface InstallCmdUploadProps {
    label: string
    setChange: (value: string) => void
    uploadDisabled?: boolean
    disabled: boolean
}

export function InstallCmdUpload({
    label,
    setChange,
    disabled,
    uploadDisabled,
}: InstallCmdUploadProps): JSX.Element {
    const placeholder = 'No file chosen'
    const fileRef = useRef() as MutableRefObject<HTMLInputElement>

    const [fileInfoHolder, setInfoNameHolder] = useState({name: placeholder, size: ''})
    const [cmdValue, setCmdValue] = useState('')
    const [initialCmdValue, setInitialCmdValue] = useState('')

    const handleUpload = () => {
        fileRef.current?.click()
    }

    function readFile(event: FileList) {
        const file = event[0]
        const reader = new FileReader()

        setInfoNameHolder({name: file.name, size: formatFileSize(file.size)})
        reader.onload = (e) => {
            const result = typeof e.target?.result == 'string' ? e.target?.result : ''
            setInitialCmdValue(result)
            setCmdValue(result)
            setChange(result)
        }
        reader.readAsText(file)
    }

    function onChangeHandler(e: ChangeEvent<HTMLTextAreaElement>) {
        e.preventDefault()
        setCmdValue(e.target.value)
        setChange(e.target.value)
    }

    return (
        <DataCellFixed label={label}>
            <Styled.Wrapper>
                <Styled.Container disabled={disabled}>
                    <Styled.DisplayFileName
                        uploadDisabled={uploadDisabled || true}
                        disabled={disabled}
                    >
                        {fileInfoHolder.name}
                    </Styled.DisplayFileName>
                    {!disabled && (
                        <Styled.DisplayFileSize>{fileInfoHolder.size}</Styled.DisplayFileSize>
                    )}
                    <Styled.CloseIcon
                        disabled={fileRef.current?.value == ''}
                        onClick={() => {
                            fileRef.current.value = ''
                            setInfoNameHolder({name: placeholder, size: ''})
                            setChange('')
                            setCmdValue('')
                        }}
                    >
                        <X />
                    </Styled.CloseIcon>
                </Styled.Container>
                <Styled.UploadButton onClick={handleUpload} disabled={uploadDisabled}>
                    Choose File
                </Styled.UploadButton>
                <input
                    ref={fileRef}
                    type="file"
                    id="command_file"
                    name="command"
                    onChange={(e) => e.target.files && readFile(e.target.files)}
                    disabled={uploadDisabled}
                    hidden
                />
            </Styled.Wrapper>
            <TextArea
                placeholder={label}
                onChange={onChangeHandler}
                disabled={disabled}
                value={cmdValue}
            />
            <Styled.WarningMessage disabled={disabled}>
                {initialCmdValue !== cmdValue &&
                    fileInfoHolder.name !== placeholder &&
                    `Warning: This install command has been edited from selected file "${fileInfoHolder.name}"`}
            </Styled.WarningMessage>
        </DataCellFixed>
    )
}
