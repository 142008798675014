import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedQmMessages} from '../../../contexts/use-paged-qm-messages'
import {getPagesToShow, getPagingButtonsToShow} from './paging-helpers'

export function Pagination(): JSX.Element {
    const {selectedPage, pageSize, selectPage, totalNumberOfMessages} = usePagedQmMessages()
    const totalNumberOfPages = totalNumberOfMessages ? Math.ceil(totalNumberOfMessages / 10) : 0
    const {width} = useDimensions()

    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }

    return (
        <BasicPaging
            selectedPage={selectedPage}
            totalNumberOfPages={totalNumberOfPages}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width)}
        />
    )
}
