import {useEffect} from 'react'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {SearchInput} from '../search-input/search-input'
import {CheckboxSelect} from '../checkbox-select/checkbox-select'
import {DataInput} from '../data-input/data-input'
import {FileUpload} from '../file-upload/file-upload'

import {InputAreaStyle, TextStyle} from './input-area.styled'
import {InstallCmdUpload} from '../install-cmd-upload/install-cmd-upload'

export function InputArea(): JSX.Element {
    const {
        setFilename,
        setVersion,
        setTargetDir,
        setCommands,
        readTargetDirList,
        readBlockedDirList,
        showVesselSelect,
        targetDirList,
    } = useQuartermaster()

    useEffect(() => {
        async function fetchConfig() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND || 'quartermaster-web-ui'
                    }.json`,
                )
                const response = await result.json()
                readTargetDirList(response.targetDirs)
                readBlockedDirList(response.blockedDirs)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <InputAreaStyle>
            <FileUpload
                label="File"
                setChange={setFilename}
                uploadDisabled={false}
                disabled={showVesselSelect}
            />
            <DataInput label="Version Tag" setChange={setVersion} />
            <SearchInput
                label="Target directory on the Incus"
                values={targetDirList}
                setChange={setTargetDir}
                disabled={showVesselSelect}
            />
            <InstallCmdUpload
                label="Install Command"
                setChange={setCommands}
                uploadDisabled={false}
                disabled={showVesselSelect}
            />
            <TextStyle>
                <CheckboxSelect />
            </TextStyle>
        </InputAreaStyle>
    )
}
