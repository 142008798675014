import {GuidType} from '../../../../values/generic-type-defintions'
import {PagedQmMessagesState, SORT_ORDER} from './paged-qm-messages-state'

export const DEFAULT_PAGED_QM_MESSAGES_STATE: PagedQmMessagesState = {
    totalNumberOfMessages: undefined,
    totalNumberOfPages: undefined,
    pageSize: 10,
    selectedPage: 0,
    detailExpanded: new Map<GuidType, boolean>(),
    sortOrder: SORT_ORDER.ASCENDING,
}
