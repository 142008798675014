import {useContext} from 'react'
import {warn} from '../../../helpers/logging'
import {GuidType} from '../../../values/generic-type-defintions'
import {PagedQmMessagesContext} from './paged-qm-messages-context'
import * as ActionCreators from './state/action-creators'
import {DEFAULT_PAGED_QM_MESSAGES_STATE} from './types/default-paged-qm-messages-state'
import {SORT_ORDER} from './types/paged-qm-messages-state'
import {UsePagedQmMessagesResult} from './use-paged-qm-messages-result'

export function usePagedQmMessages(): UsePagedQmMessagesResult {
    const {state, dispatch} = useContext(PagedQmMessagesContext)

    function selectPage(pageIndex: number | undefined): void {
        dispatch(ActionCreators.selectPage(pageIndex))
    }

    function setTotalNumberOfMessages(value: number): void {
        dispatch(ActionCreators.SetTotalNumberOfMessages(value))
    }

    function toggleDetailExpanded(id: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.toggleDetailExpanded(id))
    }

    function setSortOrder(sortOrder: SORT_ORDER): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.sortData(sortOrder))
    }

    return {
        pageSize: state?.pageSize || DEFAULT_PAGED_QM_MESSAGES_STATE.pageSize,
        totalNumberOfMessages:
            state?.totalNumberOfMessages || DEFAULT_PAGED_QM_MESSAGES_STATE.totalNumberOfMessages,
        selectedPage: state?.selectedPage || DEFAULT_PAGED_QM_MESSAGES_STATE.selectedPage,
        totalNumberOfPages:
            state?.totalNumberOfPages || DEFAULT_PAGED_QM_MESSAGES_STATE.totalNumberOfPages,
        detailExpanded: state?.detailExpanded || DEFAULT_PAGED_QM_MESSAGES_STATE.detailExpanded,
        sortOrder: state?.sortOrder || SORT_ORDER.ASCENDING,
        selectPage,
        setTotalNumberOfMessages,
        toggleDetailExpanded,
        setSortOrder,
    }
}
