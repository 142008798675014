import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {PagedQmMessagesContext} from './paged-qm-messages-context'
import {pagedQmMessagesReducer} from './state/reducer'
import {DEFAULT_PAGED_QM_MESSAGES_STATE} from './types/default-paged-qm-messages-state'
import {PagedQmMessagesState} from './types/paged-qm-messages-state'

type PagedQmMessagesProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function PagedQmMessagesProvider({children}: PagedQmMessagesProviderProps): JSX.Element {
    const initialState: PagedQmMessagesState = DEFAULT_PAGED_QM_MESSAGES_STATE

    const [state, dispatch] = useReducer(pagedQmMessagesReducer, initialState)

    return (
        <PagedQmMessagesContext.Provider value={{state, dispatch}}>
            {children}
        </PagedQmMessagesContext.Provider>
    )
}
