import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {LightTheme} from '../../../../../theme/light-theme'
import {spacing} from '../../../../../theme/spacing'

export const Wrapper = styled.div`
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    padding: ${spacing(2)};
    font-weight: ${LightTheme.font.weight.semibold};
    width: 600px;
`

export const ScrollableList = styled.div`
    height: 500px;
    overflow-y: scroll;
`
export const Title = styled.div`
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const CustomerEntry = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${spacing(1)};
    gap: ${spacing(1)};
`
export const CustomerName = styled.div`
    margin: ${spacing(1)};
    padding: ${spacing(1)};
    border: 1px solid ${(props) => props.theme.colors.border.default};
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.background.selectedLocationPage};
`
export const LocationAndNameContainer = styled.div`
    display: flex;
    width: 80%;
    align-self: flex-end;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin-right: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
`
export const LocationCode = styled.div`
    width: 50%;
`

export const VesselName = styled.div`
    width: 50%;
`
