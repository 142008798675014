import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './_styled/qm-messages-detail-expanded.styled'

interface DataCellFixedProps {
    label: string
    children: ReactNode | ReactNodeArray
}

export function DataCellFixed({label, children}: DataCellFixedProps): JSX.Element {
    const formattedGridArea = label.toLocaleUpperCase().replace(/ /g, '-')

    return (
        <>
            <Styled.Label gridArea={formattedGridArea}>{label}</Styled.Label>
            <Styled.Value gridArea={`${formattedGridArea}-VALUE`}>{children}</Styled.Value>
        </>
    )
}
