// import {Role} from '../../../values/Role'
// import RoleChecker from '../../RoleChecker'
import {NavBarLink} from './nav-bar-link'

export function NavBarContent(): JSX.Element {
    return (
        <>
            <NavBarLink id="navigation-dashboard" path="/dashboard" navLinkText="Dashboard" />
            <NavBarLink id="navigation-output" path="/output" navLinkText="Output" />
            {/* <RoleChecker rolesAllowed={['DEVELOPER', 'THREAT_ANALYST'] as Role[]}> */}
            <NavBarLink id="navigation-system" path="/system" navLinkText="System" />
            {/* </RoleChecker> */}
        </>
    )
}
