import {Location} from '../../../../store/state/locations/state'
import {SubmitResult, VesselList} from '../types/quartermaster-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function SetFilename(value: string): Actions.SetFilenameAction {
    return {type: ActionType.SET_FILENAME, payload: value}
}

export function SetUploadedFile(value: File): Actions.SetUploadedFileAction {
    return {type: ActionType.SET_UPLOADED_FILE, payload: value}
}

export function setVersion(value: string): Actions.SetVersionAction {
    return {type: ActionType.SET_VERSION, payload: value}
}

export function readTargetDirList(value: string[]): Actions.ReadTargetDirListAction {
    return {type: ActionType.READ_TARGETDIR_LIST, payload: value}
}

export function readBlockedtDirList(value: string[]): Actions.ReadBlockedDirListAction {
    return {type: ActionType.READ_BLOCKEDDIR_LIST, payload: value}
}

export function setTargetDir(value: string): Actions.SetTargetDirAction {
    return {type: ActionType.SET_TARGETDIR, payload: value}
}

export function setCommands(value: string): Actions.SetCommandsAction {
    return {type: ActionType.SET_COMMANDS, payload: value}
}

export function addLocation(value: Location): Actions.AddLocationAction {
    return {type: ActionType.ADD_LOCATION, payload: value}
}

export function deleteLocation(value: Location): Actions.DeleteLocationAction {
    return {type: ActionType.DELETE_LOCATION, payload: value}
}

export function setVesselList(value: VesselList): Actions.SetVesselListAction {
    return {type: ActionType.SET_VESSEL_LIST, payload: value}
}

export function setDisabled(value: boolean): Actions.SetDisabledAction {
    return {type: ActionType.SET_DISABLED, payload: value}
}

export function setIsAllCustomers(value: boolean): Actions.SetIsAllCustomersAction {
    return {type: ActionType.SET_IS_ALL_CUSTOMERS, payload: value}
}

export function setShowVesselSelect(value: boolean): Actions.SetShowVesselSelectAction {
    return {type: ActionType.SET_SHOW_VESSEL_SELECT, payload: value}
}

export function setSubmitResult(value: SubmitResult): Actions.SetSubmitResultAction {
    return {type: ActionType.SET_SUBMIT_RESULT, payload: value}
}

export function setSubmitProcessing(value: boolean): Actions.SetSubmitProcessingAction {
    return {type: ActionType.SET_SUBMIT_PROCESSING, payload: value}
}

export function selectCustomer(value: string): Actions.SelectCustomerAction {
    return {type: ActionType.SELECT_CUSTOMER, payload: value}
}

export function resetChanges(): Actions.ResetDataAction {
    return {type: ActionType.RESET_DATA}
}
