import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {
    CustomerEntry,
    CustomerName,
    ScrollableList,
    LocationAndNameContainer,
    LocationCode,
    Title,
    VesselName,
    Wrapper,
} from './destination-select.styled'

export function DestinationSelect(): JSX.Element {
    const {vesselLocationList, deleteLocation} = useQuartermaster()

    return (
        <Wrapper>
            <Title>Destination List</Title>
            <ScrollableList>
                {vesselLocationList?.map((item, index) => (
                    <CustomerEntry key={index}>
                        <CustomerName>{item.customer.toUpperCase()}</CustomerName>
                        {item.locations.map((item, index) => (
                            <LocationAndNameContainer
                                key={index}
                                onClick={() => deleteLocation(item)}
                            >
                                <LocationCode>
                                    {item.customerLocationCode.locationNumber}
                                </LocationCode>
                                <VesselName>{item.description}</VesselName>
                            </LocationAndNameContainer>
                        ))}
                    </CustomerEntry>
                ))}
            </ScrollableList>
        </Wrapper>
    )
}
