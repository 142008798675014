import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const VesselSelectBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    border: 1px solid #ccc;
    margin-right: ${spacing(5)};
`

export const VesselSelectWrapper = styled.div`
    display: flex;
    justify-content: space-around;
`
