import styled from 'styled-components'
// import {LightTheme} from '../../../../../theme/light-theme'
// import {spacing} from '../../../../../theme/spacing'

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 400px;
`
// export const StyledLabel = styled.label`
//     font-family: ${LightTheme.font.family};
//     font-weight: ${LightTheme.font.weight.semibold};
//     margin-bottom: 1rem;
// `

// export const Select = styled.select`
//     text-align-last: center;
//     padding: 3px ${spacing(4)} 3px ${spacing(6)};
//     font-weight: ${(props) => props.theme.font.weight.extrabold};
//     font-size: 12px;
//     border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
//     border-radius: 3px;
//     width: 250px;
//     height: 30px;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
// `
