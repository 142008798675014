import produce from 'immer'
import ActionType from './action-type'
import {Action} from './actions'
import {DEFAULT_ENVIRONMENTS_STATE, EnvironmentsReduxState} from './state'

export const environmentsReducer = produce(
    (draft: EnvironmentsReduxState = DEFAULT_ENVIRONMENTS_STATE, action: Action) => {
        switch (action.type) {
            case ActionType.REQUEST_ENVIRONMENTS:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_ENVIRONMENTS:
                draft.isFetching = false
                draft.environments = action.payload
                break
            default:
                break
        }
        return draft
    },
)
