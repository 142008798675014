import {FormattedOutput} from '../../../../../../store/state/qm-messages/state'
import {DataCellFixed} from './data-cell-fixed-detail-expanded'
import * as Styled from './_styled/qm-messages-detail-expanded.styled'

interface QmMessagesDetailExpandedProps {
    gridRow: number
    detailExpanded: boolean
    formattedOutput: FormattedOutput
}
export function QmMessagesDetailExpanded({
    gridRow,
    detailExpanded,
    formattedOutput,
}: QmMessagesDetailExpandedProps): JSX.Element {
    return (
        <Styled.TableDetailRow gridRow={gridRow}>
            {detailExpanded && (
                <Styled.DetailSection>
                    <DataCellFixed label="Result">{formattedOutput.result}</DataCellFixed>
                    <DataCellFixed label="Output">{formattedOutput.output}</DataCellFixed>
                    <DataCellFixed label="Error">{formattedOutput.error}</DataCellFixed>
                </Styled.DetailSection>
            )}
        </Styled.TableDetailRow>
    )
}
