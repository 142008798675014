import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {fetchEnvironments} from '../../store/state/environments/action-creators'
import {fetchLocations} from '../../store/state/locations/action-creators'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {QmSubmitter} from './components/qm-submitter/qm-submitter'
import {QuartermasterProvider} from './contexts/quartermaster-provider'

export function DashboardPage(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchEnvironments())
        dispatch(fetchLocations)
    })

    return (
        <FixedPageTemplate>
            <QuartermasterProvider>
                <ContentContainerTop width={width} />
                <QmSubmitter />
            </QuartermasterProvider>
        </FixedPageTemplate>
    )
}
