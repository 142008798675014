import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {Location} from '../../../../../store/state/locations/state'

interface Props {
    location: Location
    checked: boolean
    setChecked: (location: Location, checked: boolean) => void
}

export function VesselRow({location, checked, setChecked}: Props): JSX.Element {
    return (
        <Checkbox
            id={`dropdown-${location.location}`}
            checked={checked}
            onChange={() => setChecked(location, !checked)}
            label={location?.code || 'Unknown'}
            detail={location?.description || 'Unknown'}
        />
    )
}
