import {Role} from '../../../values/Role'

export interface User {
    _id: string
    name: string
    email: string
    role: Role[]
}

export interface UserReduxState {
    isFetching: boolean
    user: User
}

export const DEFAULT_USER_STATE: UserReduxState = {
    isFetching: true,
    user: {} as User,
}
