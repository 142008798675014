type DetailExpandedMap = Map<string, boolean>

export enum SORT_ORDER {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC',
}
export interface PagedQmMessagesState {
    totalNumberOfMessages: number | undefined
    pageSize: number
    selectedPage: number | undefined
    totalNumberOfPages: number | undefined
    detailExpanded: DetailExpandedMap
    sortOrder: SORT_ORDER
}
