import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface ContainerProps {
    isExtended: boolean
    width?: number | undefined
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 370px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.default};
    border-radius: 3px;
    background: ${(props) => props.theme.colors.background.default};
    margin-left: 3px;
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) => props.theme.colors.border.lowContrast};
`

export const CloseIcon = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    color: ${(props) => props.theme.colors.border.lowContrast};
    background: ${(props) => props.theme.colors.background.default};
    cursor: pointer;
    width: 20px;
    border: 0;
    &:hover {
        color: ${(props) => props.theme.colors.border.active};
    }
    :disabled {
        opacity: 0;
        cursor: default;
    }
`

export const SearchInput = styled.input<ContainerProps>`
    ${smallFont()};
    width: 370px;
    border: 0;
    border-radius: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
        outline: none;
        &::placeholder {
            opacity: 0;
        }
    }
    &::placeholder {
        ${smallFont()};
        color: ${(props) => props.theme.colors.text.deemphasize};
    }
`
export const Wrapper = styled.div`
    display: flex;
`
