import {useQuartermaster} from '../../contexts/use-quartermaster'
import {FileSelect} from '../file-select/file-select'
import {VesselSelect} from '../vessel-select/vessel-select'
import {Wrapper} from './qm-submitter.styled'

export function QmSubmitter(): JSX.Element {
    const {showVesselSelect} = useQuartermaster()

    return (
        <Wrapper>
            <FileSelect />
            {showVesselSelect && <VesselSelect />}
        </Wrapper>
    )
}
