import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

interface TableDetailRowProps {
    gridRow: number
}

export const TableDetailRow = styled.div<TableDetailRowProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 2;
    grid-column-end: -2;
`
export const DetailSection = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'RESULT RESULT-VALUE' 'OUTPUT OUTPUT-VALUE' 'ERROR ERROR-VALUE';
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(1)};
    padding: ${spacing(2)};
    ${smallFont()}
`

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    margin: 0;
    width: 100px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
