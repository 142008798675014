import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface Props {
    disabled: boolean
}

export const Input = styled.input<Props>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    ${smallFont()}
    height: 30px;
    width: ${(props) => (props.disabled ? `200px` : `410px`)};
    margin: 3px;
    padding: ${spacing(1)};
`
