import produce from 'immer'
import {Location} from '../../../../store/state/locations/state'
import {DEFAULT_QUARTERMASTER_STATE} from '../types/default-quartermaster-state'
import {
    QuartermasterState,
    SelectedLocationsSet,
    VesselList,
    VesselLocationList,
} from '../types/quartermaster-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'

function setVesselList(
    currentCustomer: string,
    selectedLocations: SelectedLocationsSet | undefined,
    payload: Location,
    vesselList: VesselList[],
    vesselLocationList: VesselLocationList[],
    action: 'add' | 'delete',
) {
    const currentVessels = new Array<string>()
    const currentLocationVessels = new Array<Location>()
    selectedLocations?.forEach((el) => {
        if (el.customerLocationCode.customer === currentCustomer) {
            currentVessels.push(el.customerLocationCode.locationNumber)
            currentLocationVessels.push(el)
        }
    })

    action === 'add' ? selectedLocations?.add(payload) : selectedLocations?.delete(payload)

    if (
        vesselList.some((el) => el.customer.toUpperCase() === currentCustomer.toUpperCase()) &&
        vesselLocationList.some((el) => el.customer.toUpperCase() === currentCustomer.toUpperCase())
    ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        vesselList.find(
            (el) => el.customer.toUpperCase() === currentCustomer.toUpperCase(),
        )!.locations = currentVessels
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        vesselLocationList!.find(
            (el) => el.customer.toUpperCase() === currentCustomer.toUpperCase(),
        )!.locations = currentLocationVessels
    } else {
        vesselList.push({
            customer: currentCustomer.toUpperCase(),
            locations: currentVessels,
        })
        vesselLocationList.push({
            customer: currentCustomer.toUpperCase(),
            locations: currentLocationVessels,
        })
    }
}

export const quartermasterReducer = produce((draft: QuartermasterState, action: AllActions) => {
    switch (action.type) {
        case ActionType.SET_FILENAME:
            draft.filename = action.payload
            break
        case ActionType.SET_UPLOADED_FILE:
            draft.uploadedFile = action.payload
            break
        case ActionType.SET_VERSION:
            draft.waybillData.FileVersion = action.payload
            draft.waybillDataAllCustomer.FileVersion = action.payload
            break
        case ActionType.READ_TARGETDIR_LIST:
            draft.targetDirList = action.payload
            break
        case ActionType.READ_BLOCKEDDIR_LIST:
            draft.blockedDirList = action.payload
            break
        case ActionType.SET_TARGETDIR:
            draft.waybillData.FileLocation = action.payload
            draft.waybillDataAllCustomer.FileLocation = action.payload
            break
        case ActionType.SET_COMMANDS:
            draft.commands = action.payload
            break
        case ActionType.ADD_LOCATION:
            draft.selectedLocations?.add(action.payload)
            setVesselList(
                action.payload.customerLocationCode.customer,
                draft.selectedLocations,
                action.payload,
                draft.waybillData.VesselList,
                draft.vesselLocationList,
                'add',
            )
            break
        case ActionType.DELETE_LOCATION:
            draft.selectedLocations?.delete(action.payload)
            setVesselList(
                action.payload.customerLocationCode.customer,
                draft.selectedLocations,
                action.payload,
                draft.waybillData.VesselList,
                draft.vesselLocationList,
                'delete',
            )
            draft.vesselLocationList = draft.vesselLocationList.filter(
                (el) => el.locations.length > 0,
            )
            draft.waybillData.VesselList = draft.waybillData.VesselList.filter(
                (el) => el.locations.length > 0,
            )
            break
        case ActionType.SET_DISABLED:
            draft.disabled = action.payload
            break
        case ActionType.SET_IS_ALL_CUSTOMERS:
            draft.isAllCustomers = action.payload
            draft.waybillData.AllCustomers = action.payload
            break
        case ActionType.SET_SHOW_VESSEL_SELECT:
            draft.showVesselSelect = action.payload
            break
        case ActionType.SET_SUBMIT_RESULT:
            draft.submitResult = action.payload
            break
        case ActionType.SELECT_CUSTOMER:
            draft.selectedCustomer = action.payload
            break
        case ActionType.SET_SUBMIT_PROCESSING:
            draft.isSubmitProcessing = action.payload
            break
        case ActionType.RESET_DATA:
            draft = DEFAULT_QUARTERMASTER_STATE
            break
    }
    return draft
})
