import {GuidType} from '../../../values/generic-type-defintions'

export type LocationIdType = GuidType

export interface CustomerLocationCode {
    customer: string
    locationNumber: string
}
export interface Location {
    location: LocationIdType
    code: string
    description: string
    type: string
    environment: string
    customerLocationCode: CustomerLocationCode
}

export type LocationMap = Map<LocationIdType, Location>
export interface LocationsReduxState {
    isFetching: boolean
    locations: Location[]
    locationMap: LocationMap
}

export const DEFAULT_LOCATIONS_STATE: LocationsReduxState = {
    isFetching: true,
    locations: [],
    locationMap: new Map<LocationIdType, Location>(),
}
