import produce from 'immer'
import ActionType from './action-type'
import {Action} from './actions'
import {
    CustomerLocationCode,
    DEFAULT_LOCATIONS_STATE,
    Location,
    LocationIdType,
    LocationMap,
    LocationsReduxState,
} from './state'

function newLocationMap(locations: Location[]): LocationMap {
    const map = new Map<LocationIdType, Location>()
    locations.forEach((location) => map.set(location.location, location))
    return map
}

function setCustomerLocationCode(location: Location): CustomerLocationCode {
    const obj = {
        customer: location.code.slice(0, -4),
        locationNumber: location.code.substr(location.code.length - 3),
    } as CustomerLocationCode
    return obj
}

export const locationsReducer = produce(
    (draft: LocationsReduxState = DEFAULT_LOCATIONS_STATE, action: Action) => {
        switch (action.type) {
            case ActionType.REQUEST_LOCATIONS:
                draft.isFetching = true
                break
            case ActionType.RECEIVE_LOCATIONS:
                draft.isFetching = false
                draft.locations = action.payload
                draft.locations.map(
                    (location) =>
                        (location.customerLocationCode = setCustomerLocationCode(location)),
                )
                draft.locationMap = newLocationMap(action.payload)
                break
            default:
                break
        }
        return draft
    },
)
