import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {ReactNode} from 'react'
import {SORT_ORDER} from '../../../../../contexts/types/paged-qm-messages-state'
import {usePagedQmMessages} from '../../../../../contexts/use-paged-qm-messages'
import {IconStyled, TableHeaderCell, TableHeaderContent} from './header-column.styled'

interface SortableColumnProps {
    gridColumn: number
    text: string
    children?: ReactNode
}

export function SortableColumn({gridColumn, text, children}: SortableColumnProps): JSX.Element {
    const {sortOrder, setSortOrder} = usePagedQmMessages()
    return (
        <TableHeaderCell gridColumn={gridColumn}>
            <TableHeaderContent
                onClick={() =>
                    sortOrder === SORT_ORDER.ASCENDING
                        ? setSortOrder(SORT_ORDER.DESCENDING)
                        : setSortOrder(SORT_ORDER.ASCENDING)
                }
                active={true}
            >
                {text}
                <IconStyled arrowDec={sortOrder === SORT_ORDER.DESCENDING}>
                    <Icon width={12} height={17} glyph="Arrow" />
                </IconStyled>
            </TableHeaderContent>
            {children}
        </TableHeaderCell>
    )
}
