import * as Styled from './_styled/qm-messages-table-row.styled'
import {TableCell} from './qm-messages-list-table-cell'
import {formatDate} from '../../../shared/data-helper'
import {QmMessage} from '../../../../../../store/state/qm-messages/state'
import {QmMessagesDetailExpanded} from './qm-messages-detail-expanded'
import {usePagedQmMessages} from '../../../../contexts/use-paged-qm-messages'
import {TableDetailCell} from './qm-messages-list-table-detail-cell'

interface TableRowProps {
    qmMessage: QmMessage
    index: number
}

export function TableRow({qmMessage, index}: TableRowProps): JSX.Element {
    const gridRow = index * 2 + 1
    const {detailExpanded} = usePagedQmMessages()
    const isExpanded = detailExpanded.get(qmMessage._id) === true
    const formattedTimestamp = formatDate(qmMessage.formattedOutput.timestamp)

    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-sme-row_${qmMessage._id}`} />
            <TableCell content={qmMessage.vesselname} gridRow={gridRow} gridColumn={2} />
            <TableCell content={qmMessage.filename} gridRow={gridRow} gridColumn={3} />
            <TableCell content={formattedTimestamp} gridRow={gridRow} gridColumn={4} />
            <TableCell
                content={qmMessage.formattedOutput.result}
                gridRow={gridRow}
                gridColumn={5}
            />
            <TableDetailCell gridRow={gridRow} gridColumn={6} id={qmMessage._id} />
            <QmMessagesDetailExpanded
                formattedOutput={qmMessage.formattedOutput}
                gridRow={gridRow + 1}
                detailExpanded={isExpanded}
            />
        </>
    )
}
