import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {useQuartermaster} from '../../../contexts/use-quartermaster'

export function CheckboxSelect(): JSX.Element {
    const {setIsAllCustomers, isAllCustomers} = useQuartermaster()

    return (
        <Checkbox
            id="checkbox"
            checked={isAllCustomers}
            onChange={() => setIsAllCustomers(!isAllCustomers)}
            label={'Send to all customer vessels'}
        />
    )
}
