import styled from 'styled-components'
import {getHorizontalPagePadding} from '../../../../theme/helpers/page-layout'
import {MAX_SCREEN_WIDTH} from '../../../../theme/theme'

export const NavigationBar = styled.nav`
    position: fixed;
    width: 100%;
    height: 3.75rem;
    background-color: ${(props) => props.theme.navigationResponsive.dataArea.background};
    color: ${(props) => props.theme.navigationResponsive.dataArea.textColor};
    z-index: 100;
    top: 0;
`

interface Props {
    width: number | undefined
}

export const CenteredContent = styled.div<Props>`
    width: 100%;
    height: 100%;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${(props) => getHorizontalPagePadding(props.width)};
    padding-right: ${(props) => getHorizontalPagePadding(props.width)};
`
