import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface TextAreaProps {
    disabled: boolean
}

export const TextArea = styled.textarea<TextAreaProps>`
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow-y: scroll;
    ${smallFont()}
    height: 250px;
    width: ${(props) => (props.disabled ? `200px` : `500px`)};
    margin: 3px;
    padding: ${spacing(1)};
`
