import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const StyledArtefactName = styled.div`
    margin: ${spacing(3)};
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.bold};
`
