import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'
import {getGridTemplateColumns} from '../_styled/qm-messages-table-populated.styled.'

interface TableHeaderGridProps {
    width: number
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(1)};
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
`

interface TableHeaderCellProps {
    gridColumn: number
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
