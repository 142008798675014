import authReducer from './state/auth/reducer'
import configReducer from './state/config/reducer'
import userReducer from './state/user/reducer'
import sessionDataReducer from './state/session-data/reducer'
import {locationsReducer} from './state/locations/reducer'
import {environmentsReducer} from './state/environments/reducer'
import {qmMessagesReducer} from './state/qm-messages/reducer'

export const staticReducers = {
    auth: authReducer,
    config: configReducer,
    sessionData: sessionDataReducer,
    locations: locationsReducer,
    environments: environmentsReducer,
    qmMessages: qmMessagesReducer,
    user: userReducer,
}

export default staticReducers
