import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const SubmitArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: ${spacing(3)};
    padding: ${spacing(2)};
`

export const ButtonArea = styled.div`
    display: flex;
    gap: ${spacing(3)};
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
`
