export enum ActionType {
    SET_FILENAME = '[Quartermaster Context] SET FILENAME',
    SET_UPLOADED_FILE = '[Quartermaster Context] SET UPLOADED FILE',
    SET_VERSION = '[Quartermaster Context] SET VERSION',
    SET_TARGETDIR = '[Quartermaster Context] SET TARGETDIR',
    SET_COMMANDS = '[Quartermaster Context] SET COMMANDS',
    ADD_LOCATION = '[Quartermaster Context] ADD LOCATION',
    DELETE_LOCATION = '[Quartermaster Context] DELETE LOCATION',
    SET_VESSEL_LIST_MAP = '[Quartermaster Context] SET VESSEL LIST MAP',
    SET_VESSEL_LIST = '[Quartermaster Context] SET VESSEL LIST',
    SET_SUBMIT_RESULT = '[Quartermaster Context] SET SUBMIT RESULT',
    SET_DISABLED = '[Quartermaster Context] SET DISABLED',
    SET_IS_ALL_CUSTOMERS = '[Quartermaster Context] SET SET IS ALL CUSTOMERS',
    SET_SHOW_VESSEL_SELECT = '[Quartermaster Context] SET SHOW VESSEL SELECT',
    SELECT_CUSTOMER = '[Quartermaster Context] SELECT CUSTOMER',
    READ_TARGETDIR_LIST = '[Quartermaster Context] READ TARGETDIR LIST',
    READ_BLOCKEDDIR_LIST = '[Quartermaster Context] READ BLOCKEDDIR LIST',
    RESET_DATA = '[Quartermaster Context] RESET DATA',
    SUBMIT_DATA = '[Quartermaster Context] SUBMIT DATA',
    SET_SUBMIT_PROCESSING = '[Quartermaster Context] SET SUBMIT PROCESSING',
}
