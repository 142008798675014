import {ReactNode, ReactNodeArray, useReducer} from 'react'
import {quartermasterReducer} from './state/reducer'
import {QuartermasterContext} from './quartermaster-context'
import {DEFAULT_QUARTERMASTER_STATE} from './types/default-quartermaster-state'
import {QuartermasterState} from './types/quartermaster-state'

type QuartermasterProviderProps = {
    children: ReactNode | ReactNodeArray
}

export function QuartermasterProvider({children}: QuartermasterProviderProps): JSX.Element {
    const initialState: QuartermasterState = DEFAULT_QUARTERMASTER_STATE

    const [state, dispatch] = useReducer(quartermasterReducer, initialState)

    return (
        <QuartermasterContext.Provider value={{state, dispatch}}>
            {children}
        </QuartermasterContext.Provider>
    )
}
