import {LoadingModal} from '../../../../components/modals/submit-loading/submit-result-modal'
import {useQuartermaster} from '../../contexts/use-quartermaster'
import {FileSelectBox} from './file-select.styled'
import {InputArea} from './input-area/input-area'
import {SubmitArea} from './submit-area/submit-area'

export function FileSelect(): JSX.Element {
    const {showVesselSelect, isSubmitProcessing} = useQuartermaster()

    return (
        <FileSelectBox disabled={showVesselSelect}>
            <InputArea />
            <SubmitArea />
            {isSubmitProcessing && <LoadingModal />}
        </FileSelectBox>
    )
}
