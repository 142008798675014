import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface FileSelectBoxProps {
    disabled: boolean
}

export const FileSelectBox = styled.div<FileSelectBoxProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${spacing(4)};
    width: 500px;
    ${(props) => (props.disabled ? `opacity: 0.5; pointer-events: none;` : ``)};
`
