import {useQuartermaster} from '../../../contexts/use-quartermaster'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {sortedEnvironmentsSelector} from '../../selectors/sorted-environments-selector'
import {SearchInput} from '../search-input/search-input'

export function DropdownCustomer(): JSX.Element {
    const {setSelectedCustomer} = useQuartermaster()
    const environments = useTypedSelector(sortedEnvironmentsSelector)

    return <SearchInput label={'Customer'} values={environments} setChange={setSelectedCustomer} />
}
