import AppState from './app-state'
import {DEFAULT_AUTH_STATE} from '../state/auth/state'
import {DEFAULT_CONFIG_STATE} from '../state/config/state'
import {DEFAULT_SESSION_DATA_STATE} from '../state/session-data/state'
import {DEFAULT_USER_STATE} from '../state/user/state'
import {DEFAULT_LOCATIONS_STATE} from '../state/locations/state'
import {DEFAULT_ENVIRONMENTS_STATE} from '../state/environments/state'
import {DEFAULT_QM_MESSAGES_STATE} from '../state/qm-messages/state'

export const INITIAL_STORE: AppState = {
    auth: DEFAULT_AUTH_STATE,
    config: DEFAULT_CONFIG_STATE,
    sessionData: DEFAULT_SESSION_DATA_STATE,
    locations: DEFAULT_LOCATIONS_STATE,
    environments: DEFAULT_ENVIRONMENTS_STATE,
    user: DEFAULT_USER_STATE,
    qmMessages: DEFAULT_QM_MESSAGES_STATE,
}
