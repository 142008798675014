import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import {Input} from './data-input.styled'

interface DataInputProps {
    label: string
    setChange: (value: string) => void
}

export function DataInput({label, setChange}: DataInputProps): JSX.Element {
    const placeholder = label == 'Version Tag' ? '1.0a (optional)' : label.toLowerCase()
    const {showVesselSelect} = useQuartermaster()

    return (
        <DataCellFixed label={label}>
            <Input
                placeholder={placeholder}
                onChange={(e) => setChange(e.target.value)}
                disabled={showVesselSelect}
            />
        </DataCellFixed>
    )
}
