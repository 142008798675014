// import useTypedSelector from '../../hooks/use-typed-selector'
// import {currentUserSelector} from '../../store/state/user/selectors'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {LinkArea} from './components/link-area/link-area'
import {TokenArea} from './components/token-area/token-area'
import {DisplayArea} from './internal-user.styled'

export function InternalUserPage(): JSX.Element {
    // const user = useTypedSelector(currentUserSelector)
    const {width} = useDimensions()
    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}>
                {/* Internal User Only
                <UserInfo>Current User: {user.name}</UserInfo>
                <UserInfo>User Role: {user.role}</UserInfo> */}
                <DisplayArea width={width}>
                    <TokenArea />
                    <LinkArea />
                </DisplayArea>
            </ContentContainerTop>
        </FixedPageTemplate>
    )
}
