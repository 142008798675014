import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const NoDataCardStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.chrList.dataRow.text};
    background-color: ${(props) => props.theme.chrList.dataRow.background};
    width: 100%;
    height: 50px;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.chrList.dataArea.boxShadow};
    padding: ${spacing(2)};
`

export const NoDataMessage = styled.div`
    margin: ${spacing(4)} 0;
    ${smallFont()}
`
