import {ChangeEvent, RefObject, useRef, useState} from 'react'
import {Search, X} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useOnClickOutside} from '../../../../../hooks/use-on-click-outside'
import {DataCellFixed} from '../../shared/data-cell-fixed/data-cell-fixed'

import {SearchInputDropdown} from './search-input-dropdown'
import * as Styled from './search-input.styled'

interface SearchInputProps {
    label: string
    values: string[]
    setChange: (value: string) => void
}

export function SearchInput({label, values, setChange}: SearchInputProps): JSX.Element {
    const placeholder = label.toLowerCase()
    const {width} = useDimensions()
    const [searchValue, setSearchValue] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)

    const clickOutside = useRef() as RefObject<HTMLDivElement>
    useOnClickOutside(clickOutside, () => setIsExpanded(false))

    const newValues = values.filter((value) =>
        value
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(searchValue.toLowerCase().replace(/\s/g, '')),
    )

    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setSearchValue(e.target.value)
        setChange(e.target.value)
    }
    function clickCloseHandler() {
        setSearchValue('')
        setIsExpanded(false)
        setChange('')
    }
    const isEmpty = newValues.length === 0
    const isCloseDisabled = searchValue.length === 0

    return (
        <DataCellFixed label={label}>
            <div ref={clickOutside}>
                <Styled.Container isExtended width={width}>
                    <Styled.SearchIcon>
                        <Search height={15} width={15} />
                    </Styled.SearchIcon>
                    <Styled.SearchInput
                        id={`${label}-search`}
                        type="text"
                        placeholder={placeholder}
                        value={searchValue}
                        autoComplete="off"
                        onFocus={() => setIsExpanded(true)}
                        onChange={onChangeHandler}
                        isExtended={isExpanded}
                    />
                    <Styled.CloseIcon disabled={isCloseDisabled} onClick={clickCloseHandler}>
                        <X />
                    </Styled.CloseIcon>
                </Styled.Container>
                {isExpanded && (
                    <SearchInputDropdown
                        customers={newValues}
                        setSearchValue={setSearchValue}
                        setChange={setChange}
                        setIsExpanded={setIsExpanded}
                        isEmpty={isEmpty}
                    />
                )}
            </div>
        </DataCellFixed>
    )
}
