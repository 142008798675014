import {ChangeEvent, RefObject, useRef, useState} from 'react'
import {Search, X, XCircle} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useOnClickOutside} from '../../../../../hooks/use-on-click-outside'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {DataCellFixed} from '../../shared/data-cell-fixed/data-cell-fixed'
import {SearchInputDropdown} from './search-input-dropdown'
import * as Styled from './search-input.styled'

interface SearchInputProps {
    label: string
    values: string[]
    setChange: (value: string) => void
    disabled: boolean
}

export function SearchInput({label, values, setChange, disabled}: SearchInputProps): JSX.Element {
    const placeholder = label.toLowerCase()
    const {width} = useDimensions()
    const [searchValue, setSearchValue] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const {blockedDirList} = useQuartermaster()

    const clickOutside = useRef() as RefObject<HTMLDivElement>
    useOnClickOutside(clickOutside, () => setIsExpanded(false))

    const newValues = values.filter((value) =>
        value
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(searchValue.toLowerCase().replace(/\s/g, '')),
    )

    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setSearchValue(e.target.value)
        setChange(e.target.value)
    }
    function clickCloseHandler() {
        setSearchValue('')
        setIsExpanded(false)
        setChange('')
    }
    const isEmpty = newValues.length === 0
    const isCloseDisabled = searchValue.length === 0

    return (
        <DataCellFixed label={label}>
            <div ref={clickOutside}>
                <Styled.Wrapper>
                    <Styled.Container isExtended width={width} disabled={disabled}>
                        <Styled.SearchIcon>
                            <Search height={15} width={15} />
                        </Styled.SearchIcon>
                        <Styled.SearchInput
                            id={`${label}-search`}
                            type="text"
                            placeholder={placeholder}
                            value={searchValue}
                            autoComplete="off"
                            onFocus={() => setIsExpanded(true)}
                            onChange={onChangeHandler}
                            isExtended={isExpanded}
                            disabled={disabled}
                        />
                        <Styled.CloseIcon disabled={isCloseDisabled} onClick={clickCloseHandler}>
                            <X />
                        </Styled.CloseIcon>
                    </Styled.Container>
                    {blockedDirList.some((e) => e === searchValue) && (
                        <Styled.IconBox>
                            <XCircle color="#ff002b" />
                        </Styled.IconBox>
                    )}
                </Styled.Wrapper>{' '}
                {isExpanded && (
                    <SearchInputDropdown
                        targetDirs={newValues}
                        setSearchValue={setSearchValue}
                        setChange={setChange}
                        setIsExpanded={setIsExpanded}
                        isEmpty={isEmpty}
                        isblockedDir={blockedDirList.some((e) => e === searchValue)}
                    />
                )}
            </div>
        </DataCellFixed>
    )
}
