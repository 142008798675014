import {ConfigReduxState} from '../state/config/state'
import {EnvironmentsReduxState} from '../state/environments/state'
import {LocationsReduxState} from '../state/locations/state'
import {QmMessagesReduxState} from '../state/qm-messages/state'
import {SessionDataReduxState} from '../state/session-data/state'
import {UserReduxState} from '../state/user/state'
import PersistableAppState from './persistable-app-state'

interface AppState extends PersistableAppState {
    sessionData: SessionDataReduxState
    config: ConfigReduxState
    locations: LocationsReduxState
    environments: EnvironmentsReduxState
    user: UserReduxState
    qmMessages: QmMessagesReduxState
}

export function toPersistableAppState(state: AppState): PersistableAppState {
    return {
        auth: state.auth,
        // user: state.user,
    }
}

export default AppState
