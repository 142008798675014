import * as Styled from './vessel-list-checkbox.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {VesselRow} from './vessel-row'
import {Location} from '../../../../../store/state/locations/state'

function isChecked(location: Location, selectedLocations: Set<Location> | undefined): boolean {
    try {
        return selectedLocations ? selectedLocations.has(location) : false
    } catch (error) {
        const id = location.location
        // eslint-disable-next-line no-console
        console.error({id, selectedLocations, error})
        return true
    }
}

export function VesselListCheckbox(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const {selectedCustomer, selectedLocations, addLocation, deleteLocation} = useQuartermaster()
    const filteredLocations = locations.filter((location) =>
        location.customerLocationCode.customer
            .toLowerCase()
            .startsWith(selectedCustomer.toLowerCase()),
    )
    function setChecked(location: Location, checked: boolean): void {
        checked ? addLocation(location) : deleteLocation(location)
    }

    return (
        <Styled.Vessels>
            {selectedCustomer.length !== 0 &&
                filteredLocations.map((location) => {
                    return (
                        <VesselRow
                            key={location.location}
                            location={location}
                            checked={isChecked(location, selectedLocations)}
                            setChecked={setChecked}
                        />
                    )
                })}
            {selectedCustomer.length === 0 && (
                <Styled.NoCustomerMessage>No Selected Customer</Styled.NoCustomerMessage>
            )}
        </Styled.Vessels>
    )
}
