import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {QmMessage} from './state'

export const isLoadingSelector = (state: AppState): boolean =>
    state.qmMessages.loading === LoadingState.NotPopulated ||
    state.qmMessages.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState => state.qmMessages?.loading

export const qmMessagesSelector = (state: AppState): QmMessage[] => state.qmMessages?.qmMessages

export const lastUpdatedSelector = (state: AppState): Moment | null | undefined =>
    state.qmMessages.lastUpdate
