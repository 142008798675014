import {MouseEvent} from 'react'
import * as Styled from './close-button.styled'
import {useQuartermaster} from '../../../../../pages/dashboard/contexts/use-quartermaster'

export function CloseButton(): JSX.Element {
    const {setSubmitProcessing} = useQuartermaster()
    function closeChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setSubmitProcessing(false)
        window.location.reload()
    }
    return <Styled.CloseButton onClick={closeChanges}>Close</Styled.CloseButton>
}
