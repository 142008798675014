import {ButtonStyled} from '../../../../../components/button/button.styled'
import {DropdownCustomer} from '../dropdown-customer/dropdown-customer'
import {DropdownVessels} from '../dropdown-vessels/dropdown-vessels'
import {ButtonWrapper, CustomerVesselSelectorWrapper} from './customer-vessel-select.styled'

export function CustomerVesselSelect(): JSX.Element {
    return (
        <CustomerVesselSelectorWrapper>
            <DropdownCustomer />
            <DropdownVessels label={'Vessels'} />
            <ButtonWrapper>
                <ButtonStyled disabled={true} onClick={() => console.log('clear')}>
                    Clear
                </ButtonStyled>
            </ButtonWrapper>
        </CustomerVesselSelectorWrapper>
    )
}
