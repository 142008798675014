import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../../../../../theme/theme'
import {BREAK_POINT_SINGLE_COLUMN} from '../../../../shared/header/header.styled'

interface ContentContainerProps {
    width: number
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.theme.colors.background.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
`
export function getGridTemplateColumns(width: number): string {
    return width > BREAK_POINT_SINGLE_COLUMN
        ? `${spacing(1)} 250px 220px 220px auto 180px ${spacing(1)}`
        : `${spacing(1)} 250px 220px 220px auto 120px ${spacing(1)}`
}

interface TableDataGridProps {
    width: number
}

export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    gap: ${spacing(1)};
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.colors.background.lowContrast};
`
