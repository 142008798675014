import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {api} from '../../../store/configure-store'
import {fetchInitialData} from '../../../store/state/auth/action-creators'
import {loggedIn, loggingOut} from '../../../store/state/session-data/action-creators'
import {allDataIsLoadedReselector} from '../../system-reinitialising/reselect/all-data-is-loaded-reselector'
import {SpinnerPanel} from './spinner-panel'
import {useAuthenticatedSemaphore} from '../../../hooks/use-authenticated-semaphore'

enum Status {
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
}

export function LoadingDataIndicator(): JSX.Element {
    const dispatch = useDispatch()
    const allDataIsLoaded = useTypedSelector(allDataIsLoadedReselector)
    const [status, setStatus] = useState(Status.UNVERIFIED)
    const {setSemaphore} = useAuthenticatedSemaphore()

    useEffect(() => {
        switch (status) {
            case Status.UNVERIFIED:
                try {
                    api.getAuth(
                        '/api/v1/auth/verify',
                        () => {
                            setStatus(Status.VERIFIED)
                        },
                        () => {
                            dispatch(loggingOut())
                        },
                    )
                } catch {
                    dispatch(loggingOut())
                }
                break

            case Status.VERIFIED:
                dispatch(fetchInitialData())
        }
    }, [status, dispatch])

    useEffect(() => {
        if (allDataIsLoaded) {
            dispatch(loggedIn())
            setSemaphore()
        }
    }, [allDataIsLoaded, setSemaphore, dispatch])

    return <SpinnerPanel title="Logging In" />
}
