import {SORT_ORDER} from '../types/paged-qm-messages-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function selectPage(pageIndex: number | undefined): Actions.SelectPage {
    return {type: ActionType.SELECT_PAGE, payload: pageIndex}
}

export function SetTotalNumberOfMessages(value: number): Actions.SetTotalNumberOfMessages {
    return {type: ActionType.SET_TOTAL_NUMBER_OF_MESSAGES, payload: value}
}

export function toggleDetailExpanded(id: string): Actions.ToggleDetailExpandedAction {
    return {type: ActionType.TOGGLE_DETAIL_EXPANDED, payload: id}
}

export function sortData(sortOrder: SORT_ORDER): Actions.SortDataAction {
    return {type: ActionType.SORT_DATA, payload: sortOrder}
}
