/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Axios, {AxiosInstance} from 'axios'
import {Store} from 'redux'

import AppState from '../../store/types/app-state'
import {errorOccured} from '../../store/state/auth/action-creators'

const EXPIRED_SESSION = 'Session expired'

const UNAUTHORIZED = 401

export const makeAxios = (store: Store<AppState>): Promise<AxiosInstance> =>
    // load config
    fetch(
        `${process.env.PUBLIC_URL}/config/${
            process.env.REACT_APP_ALT_BACKEND || 'quartermaster-web-ui'
        }.json`,
    )
        .then((response) => {
            return response.json()
        })
        .then((json) =>
            // create axios
            Axios.create({
                baseURL: json.baseUrl,
                // timeout: 50000,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        )
        .then((axiosInstance) => {
            // interceptor to add auth token
            axiosInstance.interceptors.request.use((config) => {
                config.headers!.Authorization = `Bearer ${store.getState().auth.authToken}`

                return config
            })

            axiosInstance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error && error.response && error.response.status === UNAUTHORIZED) {
                        store.dispatch(errorOccured(EXPIRED_SESSION))

                        return Promise.reject(error)
                    } else {
                        return Promise.reject(error)
                    }
                },
            )

            return axiosInstance
        })
