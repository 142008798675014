import * as Styled from './_styled/qm-messages-table-populated.styled.'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {TableRow} from './qm-messages-list-table-row'
import {QmMessage} from '../../../../../../store/state/qm-messages/state'
import {usePagedQmMessages} from '../../../../contexts/use-paged-qm-messages'
import {useEffect} from 'react'
import {SORT_ORDER} from '../../../../contexts/types/paged-qm-messages-state'

interface QmMessagesListTablePopulatedProps {
    qmMessages: QmMessage[]
}

export function QmMessagesListTablePopulated({
    qmMessages,
}: QmMessagesListTablePopulatedProps): JSX.Element {
    const {width} = useDimensions()
    const {setTotalNumberOfMessages, selectedPage, sortOrder} = usePagedQmMessages()

    useEffect(() => {
        setTotalNumberOfMessages(qmMessages.length)
    }, [setTotalNumberOfMessages, qmMessages])

    const offset = selectedPage ? selectedPage * 10 : 0
    const selectedQmMessages =
        sortOrder === SORT_ORDER.ASCENDING
            ? qmMessages
                  .slice(0)
                  .reverse()
                  .slice(offset, offset + 10)
            : qmMessages
                  .slice(0)

                  .slice(offset, offset + 10)

    return (
        <Styled.TableDataGrid width={width}>
            {selectedQmMessages.map((qmMessage, index) => (
                <TableRow key={qmMessage._id} qmMessage={qmMessage} index={index} />
            ))}
        </Styled.TableDataGrid>
    )
}
