import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

export const Vessels = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: ${spacing(1)};
`

export const NoCustomerMessage = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${spacing(4)};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
