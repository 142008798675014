import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const ArtfectNameWrapper = styled.div`
    display: flex;
`

export const CustomerVesselSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
    justify-content: flex-start;
    padding: ${spacing(2)};
    width: 400px;
    height: 555px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-item: flex-end;
    justify-content: flex-end;
`
