import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './data-cell-fixed.styled'

interface DataCellFixedProps {
    label: string
    children: ReactNode | ReactNodeArray
}

export function DataCellFixed({label, children}: DataCellFixedProps): JSX.Element {
    const formattedGridArea = label.toLocaleUpperCase().replace(/ /g, '-')
    const id = `submitter_${formattedGridArea.toLowerCase()}`

    return (
        <>
            <Styled.Label gridArea={formattedGridArea} id={`${id}-label`}>
                {label !== 'ASSET' && label}
            </Styled.Label>
            <Styled.Value gridArea={`${formattedGridArea}-VALUE`} id={`${id}-value`}>
                {children}
            </Styled.Value>
        </>
    )
}
