import {useContext} from 'react'
import {UseQuartermasterResult} from './use-quartermaster-result'
import * as ActionCreators from './state/action-creators'
import {QuartermasterContext} from './quartermaster-context'
import {SubmitResult, VesselList} from './types/quartermaster-state'
import {Location} from '../../../store/state/locations/state'
import {
    DEFAULT_SUBMIT_RESULT,
    DEFAULT_WAYBILL_DATA,
    DEFAULT_WAYBILL_DATA_All_CUSTOMER,
} from './types/default-quartermaster-state'

export function useQuartermaster(): UseQuartermasterResult {
    const {state, dispatch} = useContext(QuartermasterContext)

    function setFilename(value: string): void {
        dispatch(ActionCreators.SetFilename(value))
    }
    function setUploadedFile(value: File): void {
        dispatch(ActionCreators.SetUploadedFile(value))
    }
    function setVersion(value: string): void {
        dispatch(ActionCreators.setVersion(value))
    }
    function readTargetDirList(value: string[]): void {
        dispatch(ActionCreators.readTargetDirList(value))
    }
    function readBlockedDirList(value: string[]): void {
        dispatch(ActionCreators.readBlockedtDirList(value))
    }
    function setTargetDir(value: string): void {
        dispatch(ActionCreators.setTargetDir(value))
    }
    function setCommands(value: string): void {
        dispatch(ActionCreators.setCommands(value))
    }
    function addLocation(value: Location): void {
        dispatch(ActionCreators.addLocation(value))
    }
    function deleteLocation(value: Location): void {
        dispatch(ActionCreators.deleteLocation(value))
    }
    function setVesselList(value: VesselList): void {
        dispatch(ActionCreators.setVesselList(value))
    }
    function setDisabled(value: boolean): void {
        dispatch(ActionCreators.setDisabled(value))
    }
    function setIsAllCustomers(value: boolean): void {
        dispatch(ActionCreators.setIsAllCustomers(value))
    }
    function setShowVesselSelect(value: boolean): void {
        dispatch(ActionCreators.setShowVesselSelect(value))
    }
    function setSelectedCustomer(value: string): void {
        dispatch(ActionCreators.selectCustomer(value))
    }
    function setSubmitResult(value: SubmitResult): void {
        dispatch(ActionCreators.setSubmitResult(value))
    }
    function setSubmitProcessing(value: boolean): void {
        dispatch(ActionCreators.setSubmitProcessing(value))
    }
    function resetChanges(): void {
        dispatch(ActionCreators.resetChanges())
    }

    return {
        filename: state ? state?.filename : '',
        disabled: state ? state.disabled : true,
        isAllCustomers: state ? state.isAllCustomers : false,
        showVesselSelect: state ? state.showVesselSelect : false,
        selectedCustomer: state ? state.selectedCustomer : '',
        selectedLocations: state ? state.selectedLocations : undefined,
        vesselList: state ? state.waybillData.VesselList : [],
        vesselLocationList: state ? state.vesselLocationList : undefined,
        waybillData: state ? state.waybillData : DEFAULT_WAYBILL_DATA,
        waybillDataAllCustomer: state
            ? state.waybillDataAllCustomer
            : DEFAULT_WAYBILL_DATA_All_CUSTOMER,
        commands: state ? state.commands : '',
        uploadedFile: state ? state.uploadedFile : undefined,
        targetDirList: state ? state.targetDirList : [],
        blockedDirList: state ? state.blockedDirList : [],
        submitResult: state ? state.submitResult : DEFAULT_SUBMIT_RESULT,
        isSubmitProcessing: state ? state.isSubmitProcessing : false,
        setFilename,
        setUploadedFile,
        setVersion,
        readTargetDirList,
        readBlockedDirList,
        setTargetDir,
        setCommands,
        addLocation,
        deleteLocation,
        setVesselList,
        setDisabled,
        setIsAllCustomers,
        setShowVesselSelect,
        setSelectedCustomer,
        setSubmitResult,
        setSubmitProcessing,
        resetChanges,
    }
}
