import {CheckCircle, XCircle} from 'react-feather'
import {SubmitResult} from '../../../../../pages/dashboard/contexts/types/quartermaster-state'
import {LoadingSpinner} from '../../../../loading/loading'
import {CancelButton} from '../cancel-button/cancel-button'
import {CloseButton} from '../close-button/close-button'
import {CloseWithDataButton} from '../close-with-data-button/close-with-data-button'
import * as Styled from './submit-progress.styled'

interface SubmitProgressProps {
    filename: string
    submitResult: SubmitResult
}

export function SubmitProgress({filename, submitResult}: SubmitProgressProps): JSX.Element {
    switch (submitResult.status) {
        case 'success':
            return (
                <>
                    <CheckCircle color="#7ac943" size={40} />
                    <Styled.Text>{submitResult.message}</Styled.Text>
                    <CloseButton />
                </>
            )
        case 'failed':
            return (
                <>
                    <XCircle color="#ff002b" size={40} />
                    <Styled.Text>{submitResult.message}</Styled.Text>
                    <CloseWithDataButton />
                </>
            )
        case '':
            return (
                <>
                    <LoadingSpinner size={40} />
                    <Styled.Text>Submitting {filename}...</Styled.Text>
                    <CancelButton disabled={true} />
                </>
            )
    }
}
