import {MutableRefObject, useRef, useState} from 'react'
import {X} from 'react-feather'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {formatFileSize} from '../../shared/data-helper'
import {DataCellFixed} from '../data-cell-fixed/data-cell-fixed'
import * as Styled from './file-upload.styled'

interface DataInputProps {
    label: string
    setChange: (value: string) => void
    uploadDisabled?: boolean
    disabled: boolean
}

export function FileUpload({
    label,
    setChange,
    disabled,
    uploadDisabled,
}: DataInputProps): JSX.Element {
    const placeholder = 'No file chosen'
    const {setUploadedFile} = useQuartermaster()
    const [fileInfoHolder, setInfoNameHolder] = useState({name: placeholder, size: ''})
    const [accepted, setAccepted] = useState(true)
    const fileRef = useRef() as MutableRefObject<HTMLInputElement>

    const handleUpload = () => {
        fileRef.current?.click()
    }

    function readFile(event: FileList) {
        const file = event[0]
        const reader = new FileReader()
        setAccepted(true)
        setInfoNameHolder({name: file.name, size: formatFileSize(file.size)})
        reader.onload = () => {
            setChange(file.name)
        }

        if (file.size > 100000000) {
            reader.readAsArrayBuffer(file)
        } else {
            reader.readAsText(file)
        }

        setUploadedFile(file)
    }

    return (
        <DataCellFixed label={label}>
            <Styled.Wrapper>
                <Styled.Container disabled={disabled}>
                    <Styled.DisplayFileName
                        uploadDisabled={uploadDisabled || true}
                        disabled={disabled}
                        accepted={accepted}
                    >
                        {fileInfoHolder.name}
                    </Styled.DisplayFileName>
                    {!disabled && (
                        <Styled.DisplayFileSize>{fileInfoHolder.size}</Styled.DisplayFileSize>
                    )}
                    <Styled.CloseIcon
                        disabled={fileRef.current?.value == ''}
                        onClick={() => {
                            setAccepted(true)
                            fileRef.current.value = ''
                            setInfoNameHolder({name: placeholder, size: ''})
                            setChange('')
                        }}
                    >
                        <X />
                    </Styled.CloseIcon>
                </Styled.Container>
                <Styled.UploadButton onClick={handleUpload} disabled={uploadDisabled}>
                    Choose File
                </Styled.UploadButton>
                <input
                    ref={fileRef}
                    type="file"
                    id="command_file"
                    name="command"
                    onChange={(e) => e.target.files && readFile(e.target.files)}
                    disabled={uploadDisabled}
                    hidden
                />
            </Styled.Wrapper>
        </DataCellFixed>
    )
}
