import {ButtonStyled} from '../../../../../components/button/button.styled'
import * as Styled from './submit-area.styled'
import {useQuartermaster} from '../../../contexts/use-quartermaster'
import {createZipFile} from '../../shared/upload-helper'

export function SubmitArea(): JSX.Element {
    const {
        resetChanges,
        isAllCustomers,
        setShowVesselSelect,
        waybillDataAllCustomer,
        filename,
        commands,
        uploadedFile,
        showVesselSelect,
        setSubmitResult,
        setSubmitProcessing,
    } = useQuartermaster()

    function allCustomerHandler() {
        createZipFile(filename, uploadedFile, commands, waybillDataAllCustomer, setSubmitResult)
        setSubmitProcessing(true)
    }
    function nextHandler() {
        setShowVesselSelect(true)
    }
    function refreshComponent() {
        window.location.reload()
    }

    const notFilled =
        filename.length === 0 ||
        waybillDataAllCustomer.FileLocation.length === 0 ||
        commands.length === 0

    const isBlockedDir = waybillDataAllCustomer.FileLocation === '/tmp'

    return (
        <Styled.SubmitArea>
            {!showVesselSelect && (
                <Styled.ButtonArea>
                    <ButtonStyled
                        // disabled={false}
                        onClick={() => {
                            resetChanges()
                            refreshComponent()
                        }}
                    >
                        Reset
                    </ButtonStyled>
                    <ButtonStyled
                        disabled={notFilled || isBlockedDir}
                        onClick={() => {
                            isAllCustomers ? allCustomerHandler() : nextHandler()
                        }}
                    >
                        {isAllCustomers ? 'Submit' : 'Next'}
                    </ButtonStyled>
                </Styled.ButtonArea>
            )}
        </Styled.SubmitArea>
    )
}
