import {DataCellFixed} from '../../shared/data-cell-fixed/data-cell-fixed'
import {VesselListCheckbox} from '../vessel-list-checkbox/vessel-list-checkbox'
import {DropdownWrapper} from './dropdown-vessels.styled'

interface DropdownVesselsProps {
    label: string
}

export function DropdownVessels({label}: DropdownVesselsProps): JSX.Element {
    return (
        <DataCellFixed label={label}>
            <DropdownWrapper>
                <VesselListCheckbox />
            </DropdownWrapper>
        </DataCellFixed>
    )
}
