import {Location} from '../../../../store/state/locations/state'
import {
    VesselList,
    WaybillData,
    QuartermasterState,
    VesselLocationList,
    WaybillDataAllCystomer,
    SubmitResult,
} from './quartermaster-state'

export const DEFAULT_WAYBILL_DATA: WaybillData = {
    AllCustomers: false,
    VesselList: new Array<VesselList>(),
    FileLocation: '',
    FileVersion: '',
}

export const DEFAULT_WAYBILL_DATA_All_CUSTOMER: WaybillDataAllCystomer = {
    AllCustomers: true,
    FileLocation: '',
    FileVersion: '',
}

export const DEFAULT_SUBMIT_RESULT: SubmitResult = {
    status: '',
    message: '',
}

export const DEFAULT_QUARTERMASTER_STATE: QuartermasterState = {
    filename: '',
    commands: '',
    waybillData: DEFAULT_WAYBILL_DATA,
    waybillDataAllCustomer: DEFAULT_WAYBILL_DATA_All_CUSTOMER,
    disabled: true,
    isAllCustomers: DEFAULT_WAYBILL_DATA.AllCustomers,
    showVesselSelect: false,
    selectedCustomer: '',
    selectedLocations: new Set<Location>(),
    vesselLocationList: new Array<VesselLocationList>(),
    uploadedFile: undefined,
    targetDirList: [],
    blockedDirList: [],
    submitResult: DEFAULT_SUBMIT_RESULT,
    isSubmitProcessing: false,
}
