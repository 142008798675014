import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {fetchQmMessages} from '../../store/state/qm-messages/action-creators'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {QmMessagesList} from './components/qm-messages-list/qm-messages-list'
import {PagedQmMessagesProvider} from './contexts/paged-qm-messages-provider'

export function QmMessages(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchQmMessages())
    })

    return (
        <PagedQmMessagesProvider>
            <FixedPageTemplate>
                <QmMessagesList />
            </FixedPageTemplate>
        </PagedQmMessagesProvider>
    )
}
